import React, { useEffect, useLayoutEffect, useState } from 'react'
import { RenderMarkdown } from 'organisms'
import { useLocation } from 'react-router-dom'

function PageElem({
  text,
  active,
  href,
  handleBtnClick,
}: {
  href: string
  text: string
  active: string
  handleBtnClick: (hash: string) => void
}) {
  return (
    <button
      onClick={() => handleBtnClick(href)}
      className={` ${
        active === href ? 'font-medium  underline' : 'font-regular'
      }  text-sm md:text-base text-left hover:underline underline-offset-2 cursor-pointer lg:text-lg`}
    >
      {text}
    </button>
  )
}
export default function PrivacyPolicy() {
  const [active, setActive] = useState('')
  const location = useLocation()

  const formattedSections = [
    { value: 'overview', label: 'Overview' },
    { value: 'general', label: 'General rules' },
    { value: 'eligibility', label: 'Eligibility' },
    { value: 'acceptance', label: 'Acceptance of terms' },
    { value: 'your-account', label: 'Your Account' },
    { value: 'privacy', label: 'Privacy' },
    { value: 'license-and-access', label: 'License and Access' },
    { value: 'monitoring', label: 'Monitoring of platform' },
    { value: 'suspension', label: 'Service suspension' },
    { value: 'prohibited-uses', label: 'Prohibited Uses' },
    { value: 'limitations', label: 'Limitations of use' },
    { value: 'indemnity', label: 'Indemnity' },
    { value: 'third-party', label: 'Advertisers/ Third Party' },
    { value: 'feedback', label: 'Feedback & comments' },
    { value: 'acknowledgements', label: 'Acknowledgements' },
    { value: 'applicable-laws', label: 'Applicable Laws' },
  ]

  const handleBtnClick = (hash: string) => {
    moveToCenter(hash)
  }
  const moveToCenter = (hash: string) => {
    if (hash) {
      setActive(hash)
      const element = document.getElementById(hash)
      if (element) {
        const offset = window.innerHeight / 2 - element.offsetHeight / 1.15
        window.scrollTo({
          top: element.offsetTop - offset,
          behavior: 'smooth',
        })
      }
    }
  }
  useEffect(() => {
    const hash = location.hash
    moveToCenter(hash.substring(1))
  }, [location])

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <section className="pt-10 lg:pt-12 blade-bottom-padding-lg min-h-[300px] bg-blueGreen">
        <div className=" text-center px-4 mt-6 md:mt-8 lg:mt-0 ">
          <div className="blade-top-padding blade-top-margin-sm pb-4">
            <div className="font-medium text-2xl  text-white   mxlg:text-3xl 2xl:text-4xl leading-tight">
              Terms and Conditions
            </div>
          </div>
        </div>
      </section>
      <div className="markdown-wrapper grid lg:grid-cols-8 grid-cols-1 md:w-11/12 sm:px-5  px-3 w-container blade-top-padding-lg blade-bottom-padding-lg blade-bottom-margin-lg">
        <div className="col-span-2">
          <div className="md:hidden block pb-5 ">
            <span className="text-xl  md:text-2xl font-medium">
              Table of contents
            </span>
          </div>
          <div className=" pb-5  grid grid-cols-2 md:flex flex-col gap-3 sticky top-32">
            <span className="text-xl md:block hidden md:text-2xl font-medium">
              Table of contents
            </span>

            {formattedSections.map((elem, index: number) => {
              return (
                <PageElem
                  handleBtnClick={handleBtnClick}
                  key={`${index}`}
                  text={elem.label}
                  active={active}
                  href={elem.value}
                />
              )
            })}
          </div>
        </div>
        <div className="col-span-6  pr-4 lg:w-11/12  md:pl-3  content-wrapper">
          <div className="col-span-8 md:pl-3    content-wrapper">
            <h4 id="overview">Overview</h4>
            <p>
              Etribe Finance Private Limited, whose registered office is
              situated at Flat No. 904, Keystone Solista, Plot No. 6, Thane,
              Navi Mumbai, Maharashtra - 400706 (hereinafter shall be referred
              to as "ProsParity" or "Company" or "us" or "we" are the owner of
              the mobile application by the name ProsParity ("App") and website
              at www.prosparity.in ("Website") (App and Website are hereinafter
              together referred to as the "Platform").
            </p>
            <p>
              As a user ("User" or "You") of the Platform, You understand that
              Platform provides access between multiple Users in the form of
              consumers, financial institutions, data partners and other
              partners lenders, borrowers, service providers etc. with a view to
              facilitate lending and borrowing activities, including providing
              other financial products credit cards etc. between the Users. List
              of our lending partners is provided at Schedule 1 herewith.
            </p>
            <p>
              It is hereby clarified that the term "Users" shall mean any person
              using the Platform i.e. consumers, financial institutions,
              regulated entities, and other partners. Your use of this Platform
              implies that You have read, understood and agreed to abide by
              following, disclaimer, terms &amp; conditions ("Terms &amp;
              Conditions").
            </p>
            <p>
              These Terms &amp; Conditions read together with our privacy policy
              govern Your usage of the Platform and the terms for the purchase
              of the Products from the Platform (“Product”).
            </p>
            <p>
              Please read these Terms &amp; Conditions carefully before
              accessing or using our Platform. By accessing or using any part of
              the Platform, you agree to be bound by these Terms &amp;
              Conditions. If You do not agree to all the terms and conditions of
              this agreement, then You may not access the Platform or use any
              services provided by Us.
            </p>
            <h4 id="general">general</h4>
            <p>
              ProsParity is a platform facilitating the loan transactions
              between the borrowers and the NBFCs/Banks. All loan applications
              are approved and sanctioned by NBFCs/Banks registered with RBI and
              communicated upfront during Loan application.
            </p>
            <p>
              The Platform provides access to an online platform bringing
              together consumers, financial institutions, data partners and
              other partners willing to abide by the Terms &amp; Conditions
              herein. ProsParity’s lending partners provide various kind of
              personal loan products as specifically supplied by the Users
              through the Platform.
            </p>
            <p>
              In the event You wish to avail any of the Products displayed on
              the Platform you will be required to provide certain information
              and our representatives may contact You through phone or email to
              understand Your requirements.
            </p>
            <p>
              ProsParity provides various services, including but not limited to
              (“Services”):
            </p>
            <ol>
              <li>
                Facilitating purchase and sale of Product on its Platform;
              </li>
              <li>
                Assisting its Users to obtain a Product from various banks and
                non-banking financial companies who are the lending partners of
                ProsParity;
              </li>
              <li>
                Assisting its Users to obtain their credit report through
                authorized agents partnered with ProsParity. ProsParity will
                monitor and update the credit report obtained by the customer
                through the use of the Platform as and when ProsParity receives
                such report from the authorized agents;
              </li>
              <li>
                Provision of data analysis and social score based on device data
                and User’s Facebook, Twitter, LinkedIn or any other social media
                accounts that are linked with User’s account on the Platform.
              </li>
            </ol>
            <p>
              In the event You have registered Your phone number on the ‘Do Not
              Disturb’ registers with Your network provider, You shall ensure to
              take all steps to enable the Company’s representative’s to contact
              You via phone to provide details about different financial
              products and You shall ensure that such calls received by You are
              pursuant to You providing Us with information and You shall not
              register a compliant with the relevant authorities for the same.
            </p>
            <p>
              For the provision of the above mentioned Services, ProsParity will
              be using and sharing the information of the User with multiple
              financial institutions and other parties, as may be required for
              the purpose mentioned above and ProsParity shall also run multiple
              credit checks to be able to get the best available offers to suit
              the needs of the Users. The collection, storing, use and transfer
              of information shared by the User shall be governed in accordance
              with the Privacy Policy is available at{' '}
              <a
                className="underline underline-offset-2"
                href="https://www.prosparity.in/privacy-policy"
                target="_blank"
              >
                https://www.prosparity.in/privacy-policy
              </a>
              .
            </p>
            <h4 id="eligibility">eligibility</h4>
            <p>
              The User of this Platform unequivocally declares and agrees that
              the User is a natural/ legal person who has attained the age of at
              least 21 years and is legally allowed to enter into a contract in
              India. The User is allowed to utilize the Services in accordance
              with the terms and conditions detailed hereinafter. By registering
              on the Platform, the User hereby undertakes to agree and abide by
              the Terms &amp; Conditions detailed herein. If the User violates
              any of these Terms &amp; Conditions, or otherwise violates an
              agreement entered into through the medium of the Platform,
              ProsParity may terminate the User’s membership, delete his/her
              profile and any content or information posted online by the User
              on the Platform and / or prohibit the User from using or accessing
              the Platform at any time in its sole discretion, with or without
              notice, including without limitation if ProsParity believes that
              User is under the age of 21 years.
            </p>
            <h4 id="acceptance">acceptance of term and conditions</h4>
            <p>
              As a User of this Platform, you have agreed to the Terms &amp;
              Conditions provided hereunder or anywhere else on this Platform
              including but not limited to disclaimers on this Platform.
            </p>
            <p>
              You are advised to read and understand the said Terms &amp;
              Conditions and in case You do not wish to agree to these Terms
              &amp; Conditions, please refrain from using the Platform.
            </p>
            <p>
              ProsParity reserves the right, at its sole discretion, to change,
              modify, add or remove portions of these Terms and Conditions, at
              any time. Any changes made to these Terms and Conditions will be
              communicated to the email address of the User maintained in our
              records, within a reasonable time from the date of modification.
              ProsParity does not take responsibility in case the email address
              provided by the User is either incorrect or does not belong to the
              user. The User’s continued use of the Platform following the
              notification of modification of these Terms and Conditions will be
              construed to mean the acceptance by the User of the modified Terms
              and Conditions.
            </p>
            <h4 id="your-account">your account</h4>
            <p>
              If You wish to use the Platform, You are required to maintain an
              account and will be required to furnish certain information and
              details, including Your name, email id, contact number and any
              other information deemed necessary by ProsParity and as further
              detailed in the Privacy Policy. You are responsible for
              maintaining the confidentiality and security of Your account,
              password, activities that occur in or through Your account and for
              restricting access to Your computer to prevent unauthorized access
              to Your account. You agree to accept responsibility for all
              activities that occur under Your account or password. You should
              take all necessary steps to ensure that the password is kept
              confidential and secure and should inform us immediately if You
              have any reason to believe that Your password has become known to
              anyone else, or if the password is being, or is likely to be, used
              in an unauthorized manner. Please ensure that the details You
              provide Us with are true, correct, accurate and complete. Upon
              ProsParity gaining knowledge or having any reasonable suspicion
              that the information provided by You is wrong, inaccurate or
              incorrect, ProsParity shall immediately terminate Your account
              without any notice to You in this regard. In the event of any
              changes to the information shared by You at the time of
              registering Yourself on the Platform, You shall be responsible for
              forthwith notifying the said changes to Us.
            </p>
            <p>
              You can access and update most of the information You provided us
              on the ‘dashboard’ area of Your account after You log-in or by
              writing to Us at hello@prosparity.in. The right to use this
              Platform is personal to the User and is not transferable to any
              other person or entity.
            </p>
            <p>
              You shall at all times abide by the Terms and Conditions stated
              herein and any breach of these conditions may also lead to
              ProsParity terminating Your account and appropriate civil and
              criminal remedies will be sought against You as provided under the
              laws of India.
            </p>
            <h4 id="privacy">privacy</h4>
            <p>
              ProsParity collects certain information from You in order to
              provide the Services. ProsParity’s use of Your personal
              information is governed in accordance with the Privacy Policy.
            </p>
            <h4 id="license-and-access">license and access</h4>
            <p>
              ProsParity grants You a limited license to access and use the
              Platform for availing the Services, but not to download any
              material from it (other than page caching) or modify it, or any
              portion of it, except with express written consent of ProsParity
              and / or its affiliates, as may be applicable. Any unauthorized
              access to the Platform or any networks, servers or computer
              systems connected to Platform and any attempt to modify, adapt,
              translate or reverse engineer any part of the Platform or
              re-format or frame any portion of the pages of the Platform, save
              to the extent expressly permitted by these Terms &amp; Conditions,
              is not permitted. This license is non-transferable and does not
              permit any resale or commercial use of this Platform or its
              contents; any downloading or copying of account information for
              the benefit of anyone other than Your use; or any use of data
              mining, robots, or similar data gathering and extraction tools.
              The Platform or any portion of the Platform (including but not
              limited to any copyrighted material, trademarks, or other
              proprietary information) may not be reproduced, duplicated,
              copied, sold, resold, visited, distributed or otherwise exploited
              for any commercial purpose without express written consent of
              ProsParity and / or its affiliates, as may be applicable. Any
              unauthorized use of the Platform shall terminate the permission or
              revoke the license granted by ProsParity.
            </p>
            <p>
              You are permitted to use content delivered to You through the
              Service only on the Service. You may not copy, reproduce,
              distribute, or create derivative works from this content. Further,
              You agree not to reverse engineer or reverse compile any of the
              Service technology, including but not limited to, any Java applets
              associated with the Service.
            </p>
            <h4 id="monitoring">monitoring of the platform an your account</h4>
            <p>
              ProsParity has the right and liberty to monitor the content of the
              Platform at all times which shall include information provided in
              Your account. The monitoring of the Platform / Platform is
              important to determine the veracity of the information provided by
              You and that every User remains in consonance with the Terms &amp;
              Conditions provided herein. Subject to the Terms &amp; Conditions
              mentioned herein, ProsParity shall also have the liberty to remove
              any objectionable content which is in contravention of the Terms
              &amp; Conditions herein or share such information with any
              governmental authority as per procedures laid down by the law for
              the time being in force in India.
            </p>
            <h4 id="suspension">service suspension</h4>
            <ol className="no-bullets">
              <li>
                (a) the rights and licenses granted to You in these terms will
                end; and
              </li>
              <li>
                (b) User must stop using the Platform forthwith. ProsParity
                reserves the right to suspend or cease providing any Service and
                shall have no liability or responsibility to the User in any
                manner whatsoever if it chooses to do so.
              </li>
            </ol>
            <h4 id="prohibited-uses">prohibited uses</h4>
            <p>
              In addition to other prohibitions as set forth in the Terms &amp;
              Conditions, You are prohibited from using the Platform:
            </p>
            <ul className="no-bullets">
              <li>(a) for any unlawful purpose;</li>
              <li>
                (b) to solicit others to perform or participate in any unlawful
                acts;
              </li>
              <li>
                (c) to violate any international, federal, provincial or state
                regulations, rules, laws, or local ordinances;
              </li>
              <li>
                (d) to infringe upon or violate our intellectual property rights
                or the intellectual property rights of others;
              </li>
              <li>
                (e) to harass, abuse, insult, harm, defame, slander, disparage,
                intimidate, or discriminate based on gender, sexual orientation,
                religion, ethnicity, race, age, national origin, or disability;
              </li>
              <li>(f) to submit false or misleading information;</li>
              <li>
                (g) to upload or transmit viruses or any other type of malicious
                code that will or may be used in any way that will affect the
                functionality or operation of the Service or of any related
                website, other websites, or the Internet;
              </li>
              <li>
                (h) to collect or track the personal information of others;
              </li>
              <li>
                (i) to spam, phish, pharm, pretext, spider, crawl, or scrape;
              </li>
              <li>(j) for any obscene or immoral purpose; or</li>
              <li>
                (k) to interfere with or circumvent the security features of the
                Service or any related website, other websites, or the Internet.
                We reserve the right to terminate Your use of the Service or any
                related website for violating any of the prohibited uses.
              </li>
            </ul>
            <h4 id="limitations">
              limitation of liability and disclaimer of warranties
            </h4>
            <p>
              Notwithstanding anything to the contrary contained herein, neither
              ProsParity nor its affiliated companies, subsidiaries, officers,
              directors, employees or any related party shall have any liability
              to You or to any third party for any indirect, incidental, special
              or consequential damages or any loss of revenue or profits arising
              under or relating to these Terms &amp; Conditions. To the maximum
              extent permitted by law, You waive, release, discharge and hold
              harmless ProsParity, its affiliated and subsidiary companies, and
              each of their directors, officers, employees, and agents, from any
              and all claims, losses, damages, liabilities, expenses and causes
              of action arising out of Your use of the Platform.
            </p>
            <p>
              As a User, You also acknowledge that ProsParity has maintained and
              carried out all possible checks and balances to assure the
              credibility of the Users as listed on the Platform. The
              information provided on the Platform is based on the information
              that is provided to ProsParity. In case the User does not disclose
              any information to ProsParity, and the same is not found out at
              the first instance by ProsParity despite all measures adopted to
              certify the ratings or credibility of a User, ProsParity shall not
              be responsible for any claims or liabilities. ProsParity in any
              event is not responsible for any default in return of money that
              is borrowed from the lender and the lender shall have the
              exclusive right to initiate proceedings against the defaulter
              borrower. ProsParity shall to the best of its abilities aid the
              concerned User in terms of provision of information to initiate
              any legal proceedings. However, ProsParity shall not be
              responsible for any default on behalf of the User. ProsParity is
              an information platform and the User is entitled to conduct its
              own diligence before taking any steps to initiate the processes
              outlined in the Website.
            </p>
            <p>
              ProsParity does not make any representations or warranties on
              behalf of the financial service providers, banks or NBFCs
              partnered with ProsParity with respect to the financial products /
              loans provided by such partners on the Platform. ProsParity shall
              be responsible only to the extent of providing the Services and
              all liabilities and obligations of the customers / User with
              respect to the financial products provided by the partners shall
              be governed by the separate agreement executed between such
              financial partners and the customers and ProsParity shall have no
              liability in this regard whatsoever.
            </p>
            <p>
              YOUR USE OF THE SERVICE AND ALL INFORMATION, PRODUCTS AND OTHER
              CONTENT (INCLUDING THAT OF THIRD PARTIES) INCLUDED IN OR
              ACCESSIBLE FROM THE SERVICE IS AT YOUR SOLE RISK. THE SERVICE IS
              PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. COMPANY EXPRESSLY
              DISCLAIM ALL WARRANTIES OF ANY KIND AS TO THE SERVICE AND ALL
              INFORMATION, PRODUCTS AND OTHER CONTENT (INCLUDING THAT OF THIRD
              PARTIES) INCLUDED IN OR ACCESSIBLE FROM THE SERVICE, WHETHER
              EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED
              WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE
              AND NONINFRINGEMENT. COMPANY MAKE NO WARRANTY THAT: (i) THE
              SERVICE WILL MEET YOUR REQUIREMENTS, (ii) THE SERVICE WILL BE
              UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, (iii) THE RESULTS
              THAT MAY BE OBTAINED FROM THE USE OF THE SERVICE WILL BE ACCURATE
              OR RELIABLE, (iv) THE QUALITY OF ANY PRODUCTS, SERVICES,
              INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU
              THROUGH THE SERVICE WILL MEET YOUR EXPECTATIONS, OR (V) ANY ERRORS
              IN THE TECHNOLOGY WILL BE CORRECTED AND YOU ARE SOLELY RESPONSIBLE
              FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT
              RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL. NO ADVICE OR
              INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM COMPANY
              OR SERVICE PROVIDER THROUGH OR FROM THE SERVICE WILL CREATE ANY
              WARRANTY NOT EXPRESSLY STATED IN THESE TERMS.
            </p>
            <p>
              YOU FURTHER AGREE THAT NEITHER COMPANY OR THIRD PARTY SERVICE
              PROVIDER NOR ANY OF THEIR AFFILIATES, ACCOUNT PROVIDERS OR ANY OF
              THEIR AFFILIATES WILL BE LIABLE FOR ANY HARMS, WHICH LAWYERS AND
              COURTS OFTEN CALL DIRECT, INDIRECT, INCIDENTAL, SPECIAL,
              CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING, BUT NOT LIMITED TO,
              DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER
              INTANGIBLE LOSSES, EVEN IF COMPANY OR SERVICE PROVIDER HAS BEEN
              ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, RESULTING FROM: (i)
              THE USE OR THE INABILITY TO USE THE SERVICE / PLATFORM; (ii) THE
              COST OF GETTING SUBSTITUTE GOODS AND SERVICES, (iii) ANY PRODUCTS,
              DATA, INFORMATION OR SERVICES PURCHASED OR OBTAINED OR MESSAGES
              RECEIVED OR TRANSACTIONS ENTERED INTO, THROUGH OR FROM THE
              SERVICE; (iv) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR
              TRANSMISSIONS OR DATA; (v) STATEMENTS OR CONDUCT OF ANYONE ON THE
              SERVICE; EVEN IF THE PROVIDER HAS BEEN ADVISED PREVIOUSLY OF THE
              POSSIBILITY OF SUCH DAMAGES; OR (vii) ANY OTHER MATTER RELATING TO
              THE SERVICE. THE MAXIMUM LIABILITY OF PROSPARITY SHALL BE LIMITED
              TO THE AMOUNT ACTUALLY PAID BY YOU TO THE COMPANY FOR THE SERVICE.
            </p>
            <h4 id="indemnity">indemnity</h4>
            <p>
              You agree to indemnify, save, and hold ProsParity, its affiliates,
              contractors, employees, officers, directors, agents and its third
              party associates, licensors, and partners harmless from any and
              all claims, demands, losses, damages, and liabilities, costs and
              expenses, including without limitation legal fees and expenses,
              arising out of or related to Your use or misuse of the Services or
              of the Website or App or Platform, any violation by You of these
              Terms &amp; Conditions, or any breach of the representations,
              warranties, and covenants made by You herein or Your infringement
              of any intellectual property or other right of any person or
              entity, or as a result of any threatening, libelous, obscene,
              harassing or offensive material posted/ transmitted by You on the
              Platform. ProsParity reserves the right, at Your expense, to
              assume the exclusive defense and control of any matter for which
              You are required to indemnify ProsParity, including rights to
              settle, and You agree to cooperate with ProsParitys’ defense and
              settlement of these claims. ProsParity will use reasonable efforts
              to notify You of any claim, action, or proceeding brought by a
              third party that is subject to the foregoing indemnification upon
              becoming aware of it. This paragraph shall survive termination of
              this Terms &amp; Conditions.
            </p>
            <h4 id="third-party">
              advertisers/ third party links to the platform
            </h4>
            <p>
              ProsParity accepts no responsibility for advertisements contained
              within the Platform. ProsParity has no control over and accepts no
              responsibility for the content of any website or mobile
              application to which a link from the Platform exists. Such linked
              websites and mobile applications are provided “as is” for User’s
              convenience only with no warranty, express or implied, for the
              information provided within them. ProsParity does not provide any
              endorsement or recommendation of any third party website or mobile
              application to which the Platform provides a link. Further, the
              Users consent and agree that the content provided in the Website
              and the App shall be synced and shall be available in both
              mediums.
            </p>
            <h4 id="feedback">user comments, feedback an other submissions</h4>
            <p>
              If, at ProsParity’s request, You send certain specific submissions
              (for example contest entries) or without a request from Us, You
              send creative ideas, suggestions, proposals, plans, or other
              materials, whether online, by email, by postal mail, or otherwise
              (collectively, 'comments'), You agree that We may, at any time,
              without restriction, edit, copy, publish, distribute, translate
              and otherwise use in any medium any comments that You forward to
              Us. We are and shall be under no obligation
            </p>
            <ol>
              <li> to maintain any comments in confidence;</li>
              <li>to pay compensation for any comments; or</li>
              <li>to respond to any comments.</li>
            </ol>
            <p>
              You agree that Your comments will not violate any right of any
              third-party, including copyright, trademark, privacy, personality
              or other personal or proprietary right. You further agree that
              Your comments will not contain libellous or otherwise unlawful,
              abusive or obscene material, or contain any computer virus or
              other malware that could in any way affect the operation of the
              Service or any related website. You may not use a false e-mail
              address, pretend to be someone other than Yourself, or otherwise
              mislead Us or third-parties as to the origin of any comments. You
              are solely responsible for any comments You make and their
              accuracy. We take no responsibility and assume no liability for
              any comments posted by You or any third-party.
            </p>
            <p>
              You are licensing to the Company and its service providers, any
              information, data, passwords, materials or other content
              (collectively, “Content”) You provide through or to the Service.
              Company and service provider of Company may use, modify, display,
              distribute and create new material using such Content to provide
              the Service to You. Company and Service Provider may also use,
              sell, license, reproduce, distribute and disclose aggregate,
              non-personally identifiable information that is derived through
              Your use of the Service. By submitting Content, You automatically
              agree, or promise that the owner of such Content has expressly
              agreed that, without any particular time limit, and without the
              payment of any fees, Company and its third party service provider
              may use the Content for the purposes set out above. As between
              Company and third party service provider, Company owns Your
              confidential account information.
            </p>
            <h4 id="acknowledgements">acknowledgements</h4>
            <p>
              You acknowledge that ProsParity is a platform bringing Users
              together and that the ProsParity is not engaged in either grant of
              loan or borrowing any money or assistance for any financial
              products from any member using the ProsParity Platform. You
              acknowledge that the use of the financial products is entirely
              based on the negotiations between the Users and third party and
              neither ProsParity nor its affiliates, contractors, employees,
              officers, directors, agents and their third party associates,
              licensors and partners have any interest with regard to any
              financial products assistance procured by or to a registered User.
              You acknowledge that ProsParity will not be responsible for any
              claim or damage in case of use of the financial products.
              ProsParity in no manner warrants or guarantees the performance of
              a third party service provider that is providing services through
              the App. You acknowledge that ProsParity in no manner guarantees
              that the Users have provided all the information on this App which
              is true and correct including his address, phone numbers etc. You
              acknowledge that it is Your responsibility to verify the
              information about the person on the Platform and ProsParity is in
              no manner liable if the information provided on this App is untrue
              or incorrect. You acknowledge that ProsParity is in no manner
              responsible for any claim of money or damages in the event one
              person fails to either grant loan or a person fails to repay the
              loan or misrepresents his financial status or commits a fraud or
              cheating or any other such illegal act.
            </p>
            <h4 id="applicable-laws">applicable laws</h4>
            <p>
              Your use of this Platform and any Term &amp; Conditions stated in
              this agreement is subject to laws of India. In case of any
              disputes arising out of the use of the Website, Courts of Mumbai
              will have exclusive jurisdiction.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
